import { useParams } from "react-router-dom";
import { useReact } from "./useReact";

export function useCourseParams() {
  const isReactOnly = useReact();

  if (isReactOnly) {
    return useParams();
  }

  const path = window.location.pathname;

  if (path.match("/academics/teaching-semester")) {
    const segments = path.split("/");
    return {
      semesterSlug: segments[3],
      classId: segments[5],
    };
  }
}
